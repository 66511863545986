import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LogService } from 'src/app/services/log.service';
import { ListGroupLoggerDto, Logger } from 'src/app/services/logger.interface';
import { WebSocketService } from 'src/app/services/websocket.service';
import { getStack, formatDate, isValidJSON } from 'src/app/util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-page-logs',
  templateUrl: './page-logs.component.html',
  styleUrls: ['./page-logs.component.css']
})
export class PageLogsComponent implements OnInit {

  loading: boolean = false;
  loggers: Array<ListGroupLoggerDto> = [];
  type: string = '';
  constructor(
    private readonly logService: LogService,
    private webSocketService: WebSocketService,
    private readonly router: Router,
    private route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {

    //await this.loadData(this.type);
    this.webSocketService.listen('message').subscribe(async (data: any) => {

      Swal.fire({
        icon: 'info',
        title: data.message,
        timerProgressBar: true,
        timer: 5000,
        position: 'bottom-right',
        showConfirmButton: false
      });
      await this.loadData(this.type);
    });

    this.route.queryParams.subscribe(async params => {
      this.type = params['type'];
      await this.loadData(this.type);
    });

  }

  async loadData(type: string) {
    this.loading = true;
    this.loggers = await this.logService.getLogs(type);

    for (const logger of this.loggers) {
      if (typeof logger.logger.metadata === 'string') {
        if ((logger.logger.metadata as any).message) {
          logger.logger.metadata = JSON.parse((logger.logger.metadata as any).message);
        } else {
          // logger.logger.metadata = JSON.parse(logger.logger.metadata);
        }
      }

    }

    this.loading = false;

  }



  //una funcion que reciba una fecha y la devuelva en formato dd/mm/yyyy hh:mm:ss
  formatDate(date: string): string {
    return formatDate(date);
  }

  /*
 async manageLogger(message: string, error: string){
    await this.logService.managmentLogger(message, error);
    await this.loadData();
  }
*/
  async openDetail(message: string, error: string) {
    //window.location.href = `/private/logs/details?msg=${message}&error=${error}`;
    this.router.navigate(['private/logs/details'], { queryParams: { type: this.type, msg: message, error: error } });

  }

  getStack(log: Logger): string {
    return getStack(log);
  }

  getMetadata(log: Logger): any {
    if(isValidJSON(log.metadata)){
      return JSON.parse(log.metadata);
    }
    else
    {
      return {
        message: 'Sin Información'
      };
    }
  }

}
