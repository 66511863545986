import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { ListGroupLoggerDto, Logger } from './logger.interface';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private URL = `/api/v1/logger`;

  constructor(private httpClient: HttpClient) { }

  async getLogs(system: string) {
    const result = await lastValueFrom<Array<ListGroupLoggerDto>>(this.httpClient.get<Array<ListGroupLoggerDto>>(`${this.URL}/${system}`));
    return result;
  }

  async getLogsByMessage(system: string, message: string, error: string) {
    return await lastValueFrom<Array<Logger>>(this.httpClient.get<Array<Logger>>(`${this.URL}/message/detail`, { params: { system, message, error } }));
  }

  /*
  async managmentLogger(message: string, error: string){

    return await lastValueFrom<any>(this.httpClient.delete<any>(`${this.URL}/${message}/${error}`));
  }

*/

  async deleteLoggerById(id: number) {
    return await lastValueFrom<any>(this.httpClient.delete<any>(`${this.URL}/${id}`));
  }

  async managmentLoggerById(id: number) {

    return await lastValueFrom<any>(this.httpClient.post<any>(`${this.URL}/management/${id}`, {}));
  }

}
